import React, { FC } from 'react';

import { MediaComponentProps } from './Media.types';
import { Rendition } from './Media.types';
import clsx from 'clsx';
import { StyledMediaComponent, IconBlock } from './Media.styles';
import { Icon } from '../../atoms/Icon';
import { RichText } from '../RichText';

export const Media: FC<MediaComponentProps> = props => {
  const {
    componentId,
    fileReferenceImage,
    trackingProperties,
    dynamicMedia,
    imgAltText = '',
    loadingType = 'lazy',
    linkUrl,
    iconText,
    position,
    iconPath,
    styleclass,
  } = props;

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};
  const handleUrl = (linkUrl: string) => (linkUrl ? { href: linkUrl } : {});

  return (
    <StyledMediaComponent {...customAttributes} className={clsx(styleclass)}>
      {dynamicMedia?.renditions?.map((rendition: Rendition, index: number) => {
        const { renditionPath, mediaValue, mediaQuery } = rendition;
        const media = `(${mediaQuery}: ${mediaValue})`;
        return (
          <source
            key={index}
            media={media}
            srcSet={renditionPath}
            data-srcset={renditionPath}
            data-testid={'source-img-' + index}
          />
        );
      })}

      <img
        src={fileReferenceImage}
        className={clsx('image', loadingType)}
        alt={imgAltText}
        data-src={fileReferenceImage}
        loading={loadingType}
      />
      {iconText && (
        <IconBlock position={position || 'bottomRight'} {...handleUrl(linkUrl as string)}>
          {iconPath && <Icon iconClass={clsx(iconPath, 'icon-inverse')} />}
          {iconText && (
            <RichText
              text={iconText as string}
              componentId={componentId + '-IconText'}
              styleclass={clsx('t-label-inverse-xs', 'label-custom')}
            />
          )}
        </IconBlock>
      )}
    </StyledMediaComponent>
  );
};
