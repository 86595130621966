import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledBannerFeature = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
  }
  .center-align-controls {
    display: flex;
    justify-content: center;
    padding: ${toRem(24)} 0;
    .carouselControlType2 {
      height: ${toRem(18)};
    }
  }
  .glide__slide {
    margin: auto;
    picture,
    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .glide__arrows button::after,
  .glide__arrows button::before {
    content: '' !important;
  }
  .glide__arrows button {
    width: ${toRem(44)} !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  @media ${baseVariables.mediaQuery.md} {
    .glide__arrows button.left-arrow::after {
      content: attr(data-content) !important;
    }
    .glide__arrows button.right-arrow::before {
      content: attr(data-content) !important;
    }
    .glide__arrows button {
      width: ${toRem(74)} !important;
      margin-right: ${toRem(24)} !important;
      margin-left: ${toRem(24)} !important;
    }
  }
`;

export const BannerBlock = styled.li`
  display: flex;
  width: 100%;
  flex-flow: column-reverse;
  box-sizing: border-box;
  position: relative;
  justify-content: end;
  @media ${baseVariables.mediaQuery.lg} {
    flex-flow: row;
  }
`;

export const BannerTextSection = styled.div`
  box-sizing: border-box;
  padding: ${toRem(32)} ${toRem(32)} 0px !important;
  button {
    margin-bottom: ${toRem(24)};
  }

  @media ${baseVariables.mediaQuery.lg} {
    padding: 0 !important;
    width: ${toRem(367)};
    button {
      margin-bottom: ${toRem(0)};
    }
  }
`;

export const ImageWrapper = styled.div`
  box-sizing: border-box;
`;

export const BannerLeftSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  @media ${baseVariables.mediaQuery.lg} {
    position: absolute;
  }
`;
