import { EditableComponent } from '@adobe/aem-react-editable-components';
import { MiniCardCarouselContainer as MiniCardCarouselContainerComp } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { MiniCardCarouselContainerProps } from './MiniCardCarouselContainer.types';

export const MiniCardCarouselContainerConfig = {
  emptyLabel: 'MiniCardCarousalContainer',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/card-mini-carousel-container`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MiniCardCarouselEditableComponent = (props: any) => {
  return (
    <EditableComponent config={MiniCardCarouselContainerConfig} {...props}>
      <MiniCardCarouselContainerComp {...props} componentName={props?.model?.cqType.split('/').pop()} />
    </EditableComponent>
  );
};

export const MiniCardCarouselContainer = (props: MiniCardCarouselContainerProps) => {
  const { model } = props;
  return (
    <div
      className={clsx(
        [model?.styleclass?.includes('inverse') ? 'inverse' : ''],
        [model?.styleclass?.includes('fullbleed') ? 'm-container-fullbleed' : '']
      )}
      data-testid="mini-card-carousel"
      data-component-name="o-common-static-minicardcarouselcontainer"
    >
      <div className={clsx('container', { 'p-0': !model?.styleclass?.includes('fullbleed') })}>
        <MiniCardCarouselEditableComponent {...props} />
      </div>
    </div>
  );
};
