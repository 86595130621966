import React, { FC, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';

import { AccordionEnhancedProps } from './AccordionEnhanced.types';
import { StyledAccordionEnhanced } from './AccordionEnhanced.styles';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { AccordionHiddenClasses } from '../../constants/ApplicationConstants';

export const AccordionEnhanced: FC<AccordionEnhancedProps> = props => {
  const {
    children,
    styleclass,
    customClass,
    headerChildren,
    headerAriaLabel,
    id,
    isOpen = false,
    custom_click_track_open_value,
    custom_click_track_closed_value,
    childrenClass,
    assetVariation,
    dynamicMedia,
    iconPath,
    enableFullWidthHeader,
    disableAccResize = true,
    onAccordionClick,
    openIndex,
  } = props;

  const HEADING_SELECTOR = `.accordion__heading span[class*='icon-arrow']`;
  const ICON_ARROW_UP = `icon-arrow-up`;
  const ICON_ARROW_DOWN = `icon-arrow-down`;
  const ARIA_COLLAPSE = 'Collapse';
  const ARIA_EXPAND = 'Expand';

  const [ariaLabel, setAriaLabel] = useState('Accordion');
  const renditions = dynamicMedia?.renditions;

  useEffect(() => {
    handleAccordionEnhancedCollapse();
    if (!disableAccResize) {
      window?.addEventListener('resize', () => handleAccordionEnhancedCollapse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const headerElement = titleRef.current;
    if (headerElement) {
      setAriaLabel(headerAriaLabel || headerElement.textContent || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOpen) {
      handleAccordionEnhancedCollapse();
    } else {
      handleAccordionEnhancedExpand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, openIndex]);

  const headingRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const handleAccordionEnhancedCollapse = () => {
    const acc_body = listRef.current;
    const acc_header = headingRef.current;
    if (!isOpen) {
      acc_body?.classList?.add('collapsed', 'hide-visibility');
      acc_header?.querySelector('.icon-arrow-up')?.classList?.replace('icon-arrow-up', 'icon-arrow-down');
    }
  };

  const handleAccordionEnhancedExpand = () => {
    const acc_body = listRef.current;
    if (isOpen) {
      acc_body?.classList?.remove('collapsed', 'hide-visibility');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accordionHeaderClick = (e: any) => {
    const list = listRef?.current;
    const headerIcon = e?.currentTarget?.parentElement?.querySelector(`${HEADING_SELECTOR}`);
    const headerElement = titleRef.current;
    if (headerElement) {
      if (list?.classList?.contains('collapsed')) {
        list?.classList?.remove(...AccordionHiddenClasses);
        if (headerIcon?.classList?.contains(`${ICON_ARROW_UP}`)) {
          headerIcon?.classList?.replace(`${ICON_ARROW_UP}`, `${ICON_ARROW_DOWN}`);
        } else {
          headerIcon?.classList?.replace(`${ICON_ARROW_DOWN}`, `${ICON_ARROW_UP}`);
        }
        e?.currentTarget?.setAttribute('aria-expanded', 'true');
        /**
         * Click Tracking Value for open state of the accordion
         */
        custom_click_track_open_value &&
          e?.currentTarget?.setAttribute('custom_click_track_value', custom_click_track_open_value);
      } else if (!list?.classList?.contains('collapsed')) {
        list?.classList?.add(...AccordionHiddenClasses);
        if (headerIcon?.classList?.contains(`${ICON_ARROW_UP}`)) {
          headerIcon?.classList?.replace(`${ICON_ARROW_UP}`, `${ICON_ARROW_DOWN}`);
        } else {
          headerIcon?.classList?.replace(`${ICON_ARROW_DOWN}`, `${ICON_ARROW_UP}`);
        }
        e?.currentTarget?.setAttribute('aria-expanded', 'false');
        /**
         * Click Tracking Value to capture in closed state of accordion
         */
        custom_click_track_closed_value &&
          e?.currentTarget?.setAttribute('custom_click_track_value', custom_click_track_closed_value);
      }
    }
    onAccordionClick && onAccordionClick();
  };

  return (
    <StyledAccordionEnhanced
      data-component-name="m-ui-library-AccordionEnhanced"
      data-testid="ui-library-AccordionEnhanced"
      enableFullWidthHeader={enableFullWidthHeader}
      className={clsx(styleclass, customClass, 'acc')}
    >
      <div className={`accordion`}>
        <div className={clsx('accordion__container', 'p-0')}>
          <span className={'seperator t-horizontal-vertical-rule mb-0'}></span>
          <Button
            className={clsx(
              'accordion__heading',
              assetVariation === 'image' ? (dynamicMedia != null ? 'my-0' : '') : ''
            )}
            ariaLabel={ariaLabel}
            ariaExpanded={isOpen}
            callback={e => accordionHeaderClick(e)}
            id={`${id}_heading`}
            ref={headingRef}
          >
            {assetVariation === 'iconfont' && (iconPath?.length || 0) > 0 && (
              <Icon
                iconClass={clsx(
                  iconPath,
                  'accordion__icon',
                  styleclass?.includes('icon-decorative') ? 'icon-decorative' : '',
                  'icon-l',
                  'mr-2'
                )}
                ariaLabel={iconPath}
              />
            )}
            {assetVariation === 'image' && dynamicMedia != null && (
              <div className={clsx('accordion__image', 'mr-3')}>
                <Image
                  altText={''}
                  renditions={renditions}
                  dynamic={dynamicMedia?.dynamic}
                  defaultImageURL={dynamicMedia?.assetPath}
                  customClass="accordion_image_size"
                />
              </div>
            )}
            <div className={clsx('accordion__title align-self-center')} ref={titleRef}>
              {headerChildren}
            </div>
            <Icon iconClass={clsx(isOpen ? ICON_ARROW_UP : ICON_ARROW_DOWN, 'icon-l align-self-center')}></Icon>
          </Button>
          <div
            className={clsx(['accordion__list', childrenClass])}
            id={`${id}_body`}
            key={`accordion_item`}
            ref={listRef}
          >
            {children}
          </div>
        </div>
      </div>
    </StyledAccordionEnhanced>
  );
};
