import styled from 'styled-components';
import { toRem } from '../../styles';

export const StyledMosaic = styled.div`
  .mosaic__img {
    width: 100%;
    &--wrapper {
      border-radius: ${toRem(14)};
      [class*='icon-arrow-']:before {
        font-weight: initial;
      }
      &.wide {
        [class*='icon-arrow-']:before {
        }
      }
      .card-layered__content .card-text-wrapper .card-texts {
        bottom: ${toRem(16)};
      }
      .chevronContainer {
        height: fit-content;
      }
    }
  }
  .mosaic__cl--wrapper {
    width: fit-content;
    padding-top: ${toRem(12)};
  }
  .width-75 {
    max-width: 75%;
  }
  .width-100 {
    max-width: 100%;
  }
  .wide {
    width: fit-content;
    right: calc(100%-100vh);
  }
  .description p {
    margin-bottom: 0;
  }
  .center-align {
    display: flex;
    justify-content: center;
  }
`;
