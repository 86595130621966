import { EditableComponent } from '@adobe/aem-react-editable-components';
import { LinksCollection as LinksCollectionComp } from '@marriott/mi-ui-library';

export const CardLinksConfig = {
  emptyLabel: 'Link List',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/linklist`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LinkList = (props: any) => {
  return (
    <div data-testid="link-list" className={props?.model?.styleclass} data-component-name="o-common-static-linklist">
      <EditableComponent config={CardLinksConfig} {...props}>
        <LinksCollectionComp {...props} />
      </EditableComponent>
    </div>
  );
};
