import { EditableComponent } from '@adobe/aem-react-editable-components';
import { DataBlock as DataBlockComp } from '@marriott/mi-ui-library';
import { DataBlockProps } from './DataBlock.types';

export const DataBlockConfig = {
  emptyLabel: 'DataBlock',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/datablock`,
};

export const DataBlock = (props: DataBlockProps) => {
  return (
    <div data-testid="data-block" data-component-name="o-common-static-datablock">
      <EditableComponent config={DataBlockConfig} {...props}>
        <DataBlockComp {...props} />
      </EditableComponent>
    </div>
  );
};
