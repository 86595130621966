import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { RichText as RichTextComp } from '@marriott/mi-ui-library';
import { RichTextProps } from './RichText.types';

export const RichTextConfig = {
  emptyLabel: 'RichText',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/richtext`,
};

export const RichText = (props: RichTextProps) => (
  <div data-testid="rich-text" data-component-name="o-common-static-richtext">
    <EditableComponent config={RichTextConfig} {...props}>
      <RichTextComp {...props} />
    </EditableComponent>
  </div>
);
