/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { MosaicProps } from './Mosaic.types';
import { StyledMosaic } from './Mosaic.styles';
import { CardLayered, CardLayeredVariations } from '../CardLayered';
import { headingType, size } from '../../utils/enums/enums';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Heading } from '../../atoms/Heading';
import { Link } from '../../atoms/Link';
import Glide from '@glidejs/glide';
import DOMPurify from 'isomorphic-dompurify';
import { RichText } from '../RichText';

const renderMosaic = (props: any, mosaicLinkRef: any) => {
  return (
    <>
      <div className="col-4 d-flex flex-column align-items-end justify-content-end">
        <div className="mosaic__img--wrapper width-75 mb-3 inverse">
          <CardLayered
            cardLayeredVariations={CardLayeredVariations['Square']}
            badgeText={''}
            ctaLink={props.image1.linkUrl}
            fontSize={size.medium}
            dynamicMedia={props.image1.dynamicMedia}
            openInNewTab={props.image1.openInNewTab}
            header={props.image1.headerText}
            chevronIconSizeClass="icon-l"
            trackingProperties={props.trackingProperties}
          />
        </div>
        <div className="mosaic__img--wrapper width-100 inverse">
          <CardLayered
            cardLayeredVariations={CardLayeredVariations['Square']}
            badgeText={''}
            ctaLink={props.image2.linkUrl}
            fontSize={size.medium}
            dynamicMedia={props.image2.dynamicMedia}
            openInNewTab={props.image2.openInNewTab}
            header={props.image2.headerText}
            chevronIconSizeClass="icon-l"
            trackingProperties={props.trackingProperties}
          />
        </div>
      </div>
      <div className="col-8">
        {mosaicContent(props, mosaicLinkRef)}
        <div className="mosaic__img--wrapper wide inverse">
          <CardLayered
            cardLayeredVariations={CardLayeredVariations.Square}
            badgeText={''}
            ctaLink={props.image3.linkUrl}
            fontSize={size.medium}
            dynamicMedia={props.image3.dynamicMedia}
            openInNewTab={props.image3.openInNewTab}
            header={props.image3.headerText}
            chevronIconSizeClass="icon-l"
            trackingProperties={props.trackingProperties}
          />
        </div>
      </div>
    </>
  );
};

const mosaicContent = (props: any, mosaicLinkRef: any) => {
  const sanitizedNode = DOMPurify.sanitize(props.content.description, {
    RETURN_DOM: true,
  });
  const fragmentString = sanitizedNode.innerHTML; // Get the HTML content of the container

  return (
    <div className="mosaic__content--container col-11" tabIndex={0}>
      {props.content.eyebrow && (
        <div className="pb-2 eyebrow__wrapper">
          <Eyebrow text={props.content.eyebrow} />
        </div>
      )}
      <Heading
        variation={headingType.title}
        disableCustomClickTrack
        element={props.content.headerTag}
        titleText={props.content.headerText}
        customClass="mb-3 m-ellipsis-2lines"
        fontSize={size.small}
      />
      {props.content.description && (
        <RichText
          text={fragmentString}
          customClass={'t-font-s description m-ellipsis-3lines mb-4 mb-lg-5'}
          componentId={'DescriptionText'}
        />
      )}
      <Link
        linkClassName={clsx(
          'm-button-s m-button-secondary mb-4 mt-2',
          `${props.ctaOpenInNewTab ? 'm-button-external' : ''}`
        )}
        linkHref={props.content.linkUrl}
        trackingProperties={props.trackingProperties}
        ref={mosaicLinkRef}
        target={`${props.ctaOpenInNewTab ? '_blank' : '_self'}`}
        linkType={`${props.ctaOpenInNewTab ? 'external' : ''}`}
      >
        {props.content.linkText}
      </Link>
    </div>
  );
};

const renderListItems = (props: any, index: number) => {
  return (
    <li className="mosaic__img--wrapper inverse">
      <CardLayered
        cardLayeredVariations={CardLayeredVariations['Square']}
        badgeText={''}
        ctaLink={props[`image${index + 1}`].linkUrl}
        fontSize={size.medium}
        dynamicMedia={props[`image${index + 1}`]?.dynamicMedia}
        openInNewTab={props[`image${index + 1}`]?.openInNewTab}
        header={props[`image${index + 1}`]?.headerText}
        chevronIconSizeClass="icon-l"
        additionalWrapperClass="mosaic__cl--wrapper"
        trackingProperties={props.trackingProperties}
      />
    </li>
  );
};
const renderCarousel = (props: any, mosaicLinkRef: any) => {
  return (
    <>
      {mosaicContent(props, mosaicLinkRef)}
      <div className="glide" id={props.componentId}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">{Array.from({ length: 3 }, (_, i) => renderListItems(props, i))}</ul>
        </div>
        <div className="row col-12">
          <div className="center-align col-12 pt-2">
            <div className="carouselControlType2">
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('left-arrow m-0', 'glide__arrow--left')}
                  data-glide-dir="<"
                  aria-label="left-arrow"
                  data-custom_click_track_value={`${props.trackingProperties?.location}|${props.trackingProperties?.leftArrowDesc}|internal`}
                >
                  <span className="icon-arrow-left" data-id="left"></span>
                </button>
              </div>
              <div data-glide-el="controls[nav]">
                {Array.from({ length: 3 }, (_, i) => (
                  <button data-glide-dir={i} aria-label={`control-button-${i}`}></button>
                ))}
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('right-arrow m-0', 'glide__arrow--right')}
                  data-glide-dir=">"
                  aria-label="right-arrow"
                  data-custom_click_track_value={`${props.trackingProperties?.location}|${props.trackingProperties?.rightArrowDesc}|internal`}
                >
                  <span className="icon-arrow-right" data-id="right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Mosaic: FC<MosaicProps> = props => {
  const [isCarousel, setIsCarousel] = useState(false);
  const mosaicLinkRef = useRef(null);

  const checkIsCarousel = () =>
    window?.matchMedia('(max-width: 575px)').matches ? setIsCarousel(true) : setIsCarousel(false);

  useEffect(() => {
    checkIsCarousel();
    window.addEventListener('resize', checkIsCarousel);
  }, []);

  useEffect(() => {
    let glideInstance: any;
    if (isCarousel) {
      glideInstance = new Glide(`#${props.componentId}`, {
        type: 'slider',
        bound: true,
        perView: 1,
        startAt: 0,
        rewind: false,
      }).mount();
    }
    return () => {
      if (glideInstance) {
        glideInstance.destroy();
      }
    };
  }, [isCarousel]);
  return (
    <StyledMosaic data-component-name="m-ui-library-Mosaic" data-testid="ui-library-Mosaic">
      <div className={clsx([!isCarousel ? 'row' : '', ' col-12 px-0 py-5'])} data-testid="mosaic">
        {isCarousel ? renderCarousel(props, mosaicLinkRef) : renderMosaic(props, mosaicLinkRef)}
      </div>
    </StyledMosaic>
  );
};
