import React from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { BannerFeature as BannerFeatureComp } from '@marriott/mi-ui-library';

export const BannerFeatureConfig = {
  emptyLabel: 'BannerFeature',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/bannerfeature`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerFeature = (props: any) => {
  const model = props.model || {};
  const { styleclass, componentId } = model;
  if (model.styleclass) {
    model.styleclass = '';
  }

  return (
    <div
      data-testid="banner-feature"
      data-component-name="o-common-static-bannerfeature"
      id={componentId}
      className={clsx(styleclass, 'm-container-fullbleed')}
    >
      <EditableComponent config={BannerFeatureConfig} {...props}>
        <BannerFeatureComp {...props} />
      </EditableComponent>
    </div>
  );
};
