import styled from 'styled-components';
import { toRem, contextualVariable, baseVariables } from '@marriott/mi-ui-library';

export const StyledCardSummaryContainer = styled.div<{ isTertiaryLink: boolean }>`
  border-radius: ${toRem(14)};
  background: ${contextualVariable.brandedBackgroundColor};
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
  .card-summary-content-section {
    margin: ${toRem(8)} ${toRem(16)};
    margin-right: ${toRem(4)};
    align-self: center;
    @media ${baseVariables.mediaQuery.md} {
      margin: ${toRem(24)};
      align-self: start;
    }
    p {
      margin-bottom: ${toRem(0)};
    }
    .additional-link-cta {
      border-bottom: ${toRem(1)} solid ${contextualVariable.actionLinkBorderBottomColor};
    }
  }
  .cta-summary-btn {
    margin: ${toRem(24)};
    margin-left: ${toRem(0)};
    align-self: self-end;
    display: flex;
    padding-top: 0;
    align-items: center;
    flex: 0 0 auto;
    padding-right: ${(props: { isTertiaryLink: boolean }) => (props?.isTertiaryLink ? toRem(24) : '')};
    &:after {
      right: ${(props: { isTertiaryLink: boolean }) => (props?.isTertiaryLink ? toRem(0) : '')};
    }
  }
  .cta-summary-chevron {
    margin: ${toRem(8)} ${toRem(16)};
    margin-left: ${toRem(0)};
    font-weight: ${baseVariables?.font?.fontWeightRegular};
  }
  img {
    object-fit: cover;
    height: 100%;
    max-width: ${toRem(100)};
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(240)};
    }
  }
  .rate-block-values {
    max-width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(136)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(160)};
    }
  }
  .card-summary-rate-content-section {
    margin: ${toRem(24)};
  }
  .rate-block-price-value {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: ${toRem(4)};
    margin-bottom: ${toRem(4)};
  }
  .card-summary-rate-block-img {
    max-width: ${toRem(173)};
  }
  .rate-card-mobile-border {
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    align-items: center;
    .rate-card-heading {
      padding: 0 ${toRem(16)};
    }
  }
`;
