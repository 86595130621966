/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import Glide from '@glidejs/glide';
import clsx from 'clsx';
import { MiniCardCarouselProps } from './MiniCardCarouselContainer.types';
import { StyledCardCarousel } from './MiniCardCarouselContainer.styles';
import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { headingType, size } from '../../utils/enums/enums';
import { CardHorizontalMini } from '../CardHorizontalMini/CardHorizontalMini';
import { Themes } from '../../utils/enums/enums';

export const MiniCardCarouselContainer: FC<MiniCardCarouselProps> = (props: MiniCardCarouselProps) => {
  const {
    titleText,
    subTitleText,
    ctaLabel,
    ctaLink,
    // noOfCards,
    eyebrow,
    ctaType,
    cardsCount,
    openInaNewTab,
    totalNumberOfCards,
    trackingProperties,
    styleclass,
    cqItems,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    // noOfCardsTablet,
    isFullWidthCards = false,
  } = props;
  const [cardsPerView, setCardsPerView] = useState(3);
  const [viewPort, setViewPort] = useState('Desktop');

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const handleResize = () => {
    const sm = window.matchMedia('(max-width: 575px)');
    const md = window.matchMedia('(min-width:576px) and (max-width: 767px)');
    const lg = window.matchMedia('(min-width: 768px)and (max-width: 991px)');
    const xl = window.matchMedia('(min-width: 992px)');
    if (sm?.matches) {
      setViewPort('Mobile');
      setCardsPerView(1);
    } else if (md?.matches) {
      setViewPort('Mobile');
      setCardsPerView(1);
    } else if (lg?.matches) {
      setViewPort('Tablet');
      setCardsPerView(2);
    } else if (xl?.matches) {
      setViewPort('Desktop');
      setCardsPerView(3);
    }
  };

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });
    handleResize();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const carouselRootSelector = `#${componentId}`;
    if (document?.querySelector(carouselRootSelector)) {
      try {
        new Glide(carouselRootSelector, {
          type: 'slider',
          bound: true,
          perView: 3,
          startAt: 0,
          rewind: false,
          gap: 8,
          autoplay: false,
          dragThreshold: false,
          breakpoints: {
            768: {
              perView: 2,
            },
            575: {
              perView: 1,
            },
          },
        }).mount({ ArrowDisabler });

        document
          ?.querySelector(`#${componentId}_left_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
        document
          ?.querySelector(`#${componentId}_right_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorMode, componentId, cardsPerView]);

  const buttonCallback = () => {
    window.open(ctaLink, openInaNewTab ? '_blank' : '_self');
  };

  const ArrowDisabler = function (Glide: any, Components: any) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }
        Glide.on(['mount.after', 'run'], () => {
          // Filter out arrows_control
          for (const controlItem of Components.Controls.items) {
            if (controlItem.className !== 'glide__arrows') {
              continue;
            }

            // Set left arrow state
            const left = controlItem?.querySelector('.glide__arrow--left');
            if (left) {
              if (Glide.index === 0) {
                left.classList.add('.is-disabled'); // Disable on first slide
              } else {
                left.classList.remove('.is-disabled'); // Enable on other slides
              }
            }

            // Set right arrow state
            const right = controlItem?.querySelector('.glide__arrow--right');
            if (right) {
              if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                right.classList.add('.is-disabled'); // Disable on last slide
              } else {
                right.classList.remove('.is-disabled'); // Disable on other slides
              }
            }
          }
        });
      },
    };
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  // This function is used to render the component in authoring mode
  const cardContainer = (index: number, innerCompStyleClass: string | undefined) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={clsx(innerCompStyleClass)}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const cardComponent = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          return (
            <CardHorizontalMini
              header={item.header}
              headerShort={item.headerShort}
              headerTag={item.headerTag}
              ctaLink={item.ctaLink}
              img={item.icon}
              dynamicMedia={item.dynamicMedia}
              openInaNewTab={item.openInaNewTab}
              trackingProperties={item.trackingProperties}
              styleclass={`${Themes.Standard} minicardcontiner`}
              // ctaLinkText={item.ctaLinkText}
              descriptionShort={item.descriptionShort}
              descriptionText={item.descriptionText}
              assetVariation={item.assetVariation}
              iconPath={item.iconPath}
              custom_click_track_value={item.custom_click_track_value}
            />
          );
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
    return null;
  };

  const GetCarComponentsForDesktop = (totalNumberOfCards: any, cqItems: any) => {
    const cards = [];
    const cardsInCol = Math.ceil(totalNumberOfCards?.length / 3);
    for (let i = 0; i < totalNumberOfCards?.length; i = i + cardsInCol) {
      cards.push(
        <li className="container p-0 containerli" key={`card-${i}`}>
          <div>
            {Array.from({ length: cardsInCol }, (_, x) => {
              return totalNumberOfCards[x + i] ? cardComponent(totalNumberOfCards[x + i], cqItems) : <div></div>;
            })}
          </div>
        </li>
      );
    }
    return cards;
  };
  const GetCarComponentsForMobile = (totalNumberOfCards: any, cqItems: any) => {
    const cards = [];
    for (let i = 0; i < totalNumberOfCards?.length; i = i + 3) {
      cards.push(
        <li className="container p-0 containerli" key={`card-${i}`}>
          <div>
            {cardComponent(totalNumberOfCards[i], cqItems)}
            {cardComponent(totalNumberOfCards[i + 1], cqItems)}
            {cardComponent(totalNumberOfCards[i + 2], cqItems)}
          </div>
        </li>
      );
    }
    return cards;
  };
  const GetCarComponentsForTab = (totalNumberOfCards: any, cqItems: any) => {
    const cards = [];
    for (let i = 0; i < totalNumberOfCards?.length; i = i + 3) {
      cards.push(
        <li className="container p-0 containerli" key={`card-${i}`}>
          <div>
            {cardComponent(totalNumberOfCards[i], cqItems)}
            {cardComponent(totalNumberOfCards[i + 1], cqItems)}
            {cardComponent(totalNumberOfCards[i + 2], cqItems)}
          </div>
        </li>
      );
    }
    return cards;
  };
  return (
    <StyledCardCarousel
      data-component-name="m-ui-library-MiniCardCarouselContainer"
      data-testid="ui-library-MiniCardCarouselContainer"
      className={clsx(`col-12 py-3 px-0 ${styleclass}`)}
      {...customAttributes}
    >
      <div className={clsx('carousal_header')}>
        <div className="heading_container">
          {titleText && <Heading titleText={titleText} variation={headingType.title} fontSize={size.small} />}
        </div>
        {ctaLabel && (
          <div className={clsx('carousal_header--cta')}>
            <Button
              href={ctaLink}
              isLink={ctaType === 'tertiaryLink'}
              target={openInaNewTab ? '_target' : '_self'}
              callback={buttonCallback}
              className={[
                '',
                ctaType === 'primaryButton' ? 'm-button-primary' : '',
                ctaType === 'secondaryButton' ? 'm-button-secondary' : '',
                ctaType === 'tertiaryLink' ? 'm-link-tertiary-button' : '',
                openInaNewTab
                  ? ctaType === 'primaryButton' || ctaType === 'secondaryButton'
                    ? 'm-button-external'
                    : 'm-link-tertiary-button-external'
                  : '',
              ]}
              custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
                openInaNewTab ? 'external' : 'internal'
              }`}
              buttonCopy={ctaLabel}
              isTextBeforeChildren={false}
            />
          </div>
        )}
      </div>
      <div id={componentId} className={clsx('glide')}>
        <div className={clsx(!subTitleText && !eyebrow ? 'glide__track__pt3' : 'glide__track')} data-glide-el="track">
          <ul
            className={clsx('glide__slides', 'pb-3', isFullWidthCards ? 'full-width-cards-slides' : '')}
            id={`${componentId}__slides`}
          >
            {isAuthorMode &&
              !isFullWidthCards &&
              Array.from({ length: totalNumberOfCards?.length }, (_, i) => cardContainer(i, styleclass))}
            {
              isAuthorMode === false
                ? viewPort === 'Mobile'
                  ? GetCarComponentsForMobile(totalNumberOfCards, cqItems)
                  : viewPort === 'Tablet'
                  ? GetCarComponentsForTab(totalNumberOfCards, cqItems)
                  : GetCarComponentsForDesktop(totalNumberOfCards, cqItems)
                : '' // totalNumberOfCards.map((cardName: string) => cardComponent(cardName, cqItems))
            }
          </ul>
        </div>
        {viewPort !== 'Desktop' &&
          ((viewPort === 'Tablet' && cardsCount > 6 && cardsCount !== cardsPerView) ||
            (viewPort === 'Mobile' && cardsCount !== cardsPerView)) && (
            <div className="center-align">
              <div className={`carouselControlType1 ${isFullWidthCards ? 'full-width-cards-controls' : ''}`}>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx(
                      'left-arrow',
                      'glide__arrow--left',
                      `${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`
                    )}
                    id={`${componentId}_left_arrow`}
                    data-glide-dir="<"
                    aria-label="left-arrow"
                    data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.leftArrowDesc}|internal`}
                  >
                    <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                  </button>
                </div>
                <div data-glide-el="controls[nav]">
                  {Array.from({ length: Math.ceil(cardsCount / 3) - (cardsPerView - 1) }, (_, i) => (
                    <button
                      className=""
                      data-glide-dir={i}
                      aria-label={`control-button-${i}`}
                      key={cardsPerView + i}
                    ></button>
                  ))}
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx(
                      'right-arrow',
                      'glide__arrow--right',
                      `${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`
                    )}
                    id={`${componentId}_right_arrow`}
                    data-glide-dir=">"
                    aria-label="right-arrow"
                    data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.rightArrowDesc}|internal`}
                  >
                    <span className="icon-arrow-right" data-id="right"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </StyledCardCarousel>
  );
};
