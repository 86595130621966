import React, { FC } from 'react';
import clsx from 'clsx';

import { DataBlockProps } from './DataBlock.types';
import { StyledDataBlock } from './DataBlock.styles';
import { Button, Heading, Icon } from '@marriott/mi-ui-library';
import { Image } from '../../atoms/Image';
import { ButtonStyle, size, headingType } from '../../utils/enums/enums';

export const DataBlock: FC<DataBlockProps> = ({
  contentAlignment,
  dataBlockVariations,
  assetVariation,
  iconFontPath,
  iconAltText,
  iconSize,
  fileReferenceImage,
  header = '',
  headerTag,
  headingType: headingTypo = headingType.subtitle,
  description,
  ctaLinkText,
  ctaLink,
  ctaType,
  displayRule,
  descriptionNumber,
  numberField,
  openInNewTab = false,
  trackingProperties,
  styleclass,
  customClass,
  imageSize,
}) => {
  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      case 'tertiaryLink':
        return openInNewTab ? ButtonStyle.TERTIARY_BUTTON_EXTERNAL : ButtonStyle.TERTIARY_BUTTON;
      default:
        return '';
    }
  };
  const iconDecorative = styleclass?.includes('coral') ? 'icon-decorative' : '';
  const logoCleanup = iconFontPath?.replace('brands/portfolio/', '');
  const logoPath = logoCleanup?.includes('brands') ? `brand-logo-${logoCleanup?.replace('brands/', '')}` : logoCleanup;
  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);
  const isIconBlock = dataBlockVariations === 'icon';
  const isNumberBlock = dataBlockVariations === 'number';
  return (
    <StyledDataBlock
      data-component-name="m-ui-library-DataBlock"
      data-testid="ui-library-DataBlock"
      className={clsx(
        styleclass,
        customClass,
        `${isIconBlock && contentAlignment === 'center' ? 'carblock-center-align' : ''}`
      )}
    >
      <div className={clsx(`datablock-container`, `${isNumberBlock && displayRule === 'vertical' && 'd-flex'}`)}>
        {isIconBlock && (
          <>
            {assetVariation === 'iconFont' && (
              <Icon
                iconClass={clsx(
                  iconSize === 'small' ? 'icon-l' : iconSize === 'large' ? 'data-block-large-icon' : '',
                  logoPath,
                  iconDecorative,
                  'data-block-logo-icon'
                )}
                ariaLabel={iconAltText}
              />
            )}
            {assetVariation === 'image' && (
              <div className="data-block-img">
                <Image
                  customClass={imageSize}
                  altText={fileReferenceImage?.altText}
                  renditions={fileReferenceImage?.renditions}
                  dynamic={fileReferenceImage?.dynamic}
                  defaultImageURL={fileReferenceImage?.assetPath}
                />
              </div>
            )}
            <div className="data-block-text-content">
              {header && (
                <Heading
                  element={headerTag}
                  variation={headingTypo}
                  titleText={header}
                  fontSize={size.medium}
                  customClass={'data-block-heading'}
                />
              )}
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} className={clsx('t-font-alt-s')}></div>
              )}
            </div>
            {ctaLinkText && (
              <Button
                isLink={true}
                href={ctaLink}
                className={`${buttonTypeClasses} ${ctaType !== 'tertiaryLink' ? ' m-button-s' : ''}`}
                target={openInNewTab === true ? '_blank' : ''}
                trackingProperties={trackingProperties}
                ariaLabel={ctaLinkText}
              >
                {ctaLinkText}
              </Button>
            )}
          </>
        )}
        {isNumberBlock && (
          <>
            {displayRule === 'horizontal' && (
              <div className={clsx('seperator t-horizontal-vertical-rule seperator-horizontal')}></div>
            )}
            {displayRule === 'vertical' && <div className="seperator t-horizontal-vertical-rule seperator-vertical" />}
            <div className="data-block-number-content">
              {numberField && <span className="t-title-s">{numberField}</span>}
              {descriptionNumber && (
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionNumber }}
                  className={clsx('t-font-alt-s data-block-number-desc')}
                ></div>
              )}
            </div>
          </>
        )}
      </div>
    </StyledDataBlock>
  );
};
