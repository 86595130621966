import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { Heading } from '../../atoms/Heading';
import { Image } from '../../atoms/Image';
import { ButtonStyle, headingType, size } from '../../utils/enums/enums';
import { StyledCardSummaryContainer } from './CardSummary.styles';
import { CardSummaryProps } from './CardSummary.types';
import { Button, Link, Modal, RichText } from '@marriott/mi-ui-library';

const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
  switch (ctaType) {
    case 'primaryButton':
      return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
    case 'secondaryButton':
      return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
    case 'tertiaryLink':
      return openInNewTab ? ButtonStyle.TERTIARY_BUTTON_EXTERNAL : ButtonStyle.TERTIARY_BUTTON;
    default:
      return '';
  }
};

export const CardSummary: FC<CardSummaryProps> = ({
  ctaLink = '',
  componentId,
  ctaLabel = '',
  trackingProperties,
  ctaType,
  addLinkInModal,
  fileReferenceImage,
  additionalLinkText = '',
  description,
  cardSummaryType,
  addLinkInNewTab = false,
  additionalLinkUrl = '',
  header,
  headerTag,
  openInNewTab = false,
  modalHeader = '',
  modalDescription = '',
  styleclass,
  customclass,
  price,
  points,
  currency,
  totalPrice,
  rateHeader,
  rateHeaderTag,
  rateDescription,
  rateAdditionalDesc,
  rateLinkText,
  rateLinkUrl,
  rateOpenInModal,
  rateAddOpenInNewTab,
}) => {
  const [viewPort, setViewPort] = useState('Desktop');
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const linkClassName = ['m-link pb-0 t-font-s additional-link-cta'];
  const isMobileView = viewPort === 'Mobile';
  const isTabletView = viewPort === 'Tablet';

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
  };

  const descriptionRef = useRef<HTMLDivElement>(null);

  const buttonTypeClasses = useMemo(() => getButtonStyle(ctaType || '', openInNewTab), [ctaType, openInNewTab]);

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
    const lg = window.matchMedia('(min-width: 1200px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ImageContent = () => {
    return (
      <Image
        customClass={clsx({ 'card-summary-rate-block-img': cardSummaryType === 'rateblock' })}
        altText={fileReferenceImage?.altText}
        renditions={fileReferenceImage?.renditions}
        dynamic={fileReferenceImage?.dynamic}
        defaultImageURL={fileReferenceImage?.assetPath}
      />
    );
  };

  const LinkContent = () => {
    const shouldRenderLink =
      !addLinkInModal &&
      (!isMobileView || cardSummaryType === 'rateblock') &&
      ((cardSummaryType === 'rateblock' && rateLinkUrl && rateLinkText) || (additionalLinkText && additionalLinkUrl));

    if (!shouldRenderLink) return null;
    return (
      <div className="mt-2">
        <Link
          linkClassName={linkClassName}
          linkHref={cardSummaryType === 'rateblock' ? (rateLinkUrl as string) : additionalLinkUrl}
          target={
            (cardSummaryType === 'rateblock' ? rateAddOpenInNewTab : addLinkInNewTab) === true ? '_blank' : '_self'
          }
          rel={`${
            (cardSummaryType === 'rateblock' ? rateAddOpenInNewTab : addLinkInNewTab) ? 'noopener noreferrer' : ''
          }`}
        >
          {cardSummaryType === 'rateblock' ? rateLinkText : additionalLinkText}
        </Link>
      </div>
    );
  };

  const ModalContent = () => {
    if ((!addLinkInModal && !rateOpenInModal) || (!additionalLinkText && !rateLinkText)) return null;
    return (
      <div className={clsx('mt-2')}>
        <Link
          linkClassName={linkClassName}
          linkHref={'#'}
          callback={e => {
            e.preventDefault();
            toggleModal();
          }}
        >
          {cardSummaryType === 'rateblock' ? rateLinkText : additionalLinkText}
        </Link>
        <Modal show={popupOpenState} secondaryClassName="standard t-background-color">
          <Modal.Header labelText={modalHeader} popupHeaderOnCLoseFunc={toggleModal} />
          <Modal.Body>
            <RichText componentId={`ctasummary-richtext-${componentId}`} text={modalDescription}></RichText>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const CTAButtonContent = () => {
    if (isMobileView) return null;
    return (
      <Button
        isLink={true}
        href={ctaLink}
        className={`cta-summary-btn ${buttonTypeClasses} ${ctaType !== 'tertiaryLink' ? ' m-button-s' : ''}`}
        target={openInNewTab === true ? '_blank' : '_self'}
        trackingProperties={trackingProperties}
        ariaLabel={ctaLabel}
      >
        {ctaLabel}
      </Button>
    );
  };

  const ChevronIcon = () => {
    if (!isMobileView) return null;
    return (
      <div className="cta-summary-chevron d-flex align-items-center">
        <span className={clsx('icon-l icon-arrow-right')}></span>
      </div>
    );
  };
  const RateDetailsCss = styleclass === 'inverse' ? 't-label-inverse-xs' : 't-label-alt-xs';

  const RateDetails = () => {
    if (!points && !price) return null;
    return (
      <div className="cta-summary-btn t-numbers-s m-price d-flex aign-items-baseline">
        <div className="rate-block-values">
          {points ? (
            <>
              <div className="t-subtitle-xl">{points}</div>
              <div className={clsx(RateDetailsCss)}>Points/Stay</div>
            </>
          ) : (
            <>
              <div className="rate-block-price-value">
                <div className="t-subtitle-xl">{price}</div>
                <div className={clsx(RateDetailsCss)}>{currency} Avg / Night</div>
              </div>
              <div className={clsx(RateDetailsCss)}>
                {totalPrice} {currency} Total
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const getHeader = () => {
    return (
      <Heading
        customClass={clsx(
          'mb-0',
          isMobileView ? 'm-ellipsis-2lines' : 'm-ellipsis-1line',
          cardSummaryType === 'rateblock' && isMobileView ? 'rate-card-heading' : ''
        )}
        variation={headingType.subtitle}
        fontSize={isMobileView ? (cardSummaryType === 'rateblock' ? size.medium : size.large) : size.extraLarge}
        element={cardSummaryType === 'rateblock' ? rateHeaderTag : headerTag}
        dangerouslySet={cardSummaryType === 'rateblock' ? rateHeader : header}
      />
    );
  };

  const getDescription = () => {
    if (!rateDescription && !description) return null;
    const text = cardSummaryType === 'rateblock' ? rateDescription : description;
    return (
      <RichText
        text={text as string}
        ref={descriptionRef}
        componentId={`${componentId}-CardSummaryDescriptionText`}
        customClass={clsx(
          't-font-s',
          cardSummaryType === 'rateblock'
            ? isMobileView
              ? ''
              : isTabletView
              ? 'm-ellipsis-2lines'
              : 'm-ellipsis-1line'
            : isMobileView
            ? 'm-ellipsis-1line'
            : 'm-ellipsis-2lines'
        )}
      />
    );
  };

  const getCardContent = () => {
    return (
      <>
        <ImageContent />
        <div className={clsx('flex-fill card-summary-content-section')}>
          {getHeader()}
          {getDescription()}
          <LinkContent />
          <ModalContent />
        </div>
        <CTAButtonContent />
        <ChevronIcon />
      </>
    );
  };

  const getRateBlock = () => {
    if (!rateAdditionalDesc) return null;
    return (
      cardSummaryType === 'rateblock' &&
      rateAdditionalDesc && (
        <RichText
          text={rateAdditionalDesc}
          ref={descriptionRef}
          componentId={componentId + '-CardSummaryDescriptionText'}
          customClass={clsx('t-font-xs', 'mt-2', { 'm-ellipsis-2lines': !isMobileView })}
        />
      )
    );
  };

  const getRateCardContent = () => {
    return (
      <>
        <ImageContent />
        <div className={clsx('flex-fill card-summary-content-section')}>
          {getHeader()}
          {getDescription()}
          {rateOpenInModal ? <ModalContent /> : <LinkContent />}
          {getRateBlock()}
        </div>
        <RateDetails />
      </>
    );
  };

  const getRateCardContentMobile = () => {
    return (
      <>
        <div className={clsx('d-flex rate-card-mobile-border')}>
          <ImageContent />
          {getHeader()}
        </div>
        <div className="card-summary-rate-content-section">
          {getDescription()}
          {rateOpenInModal ? <ModalContent /> : <LinkContent />}
          {getRateBlock()}
          <RateDetails />
        </div>
      </>
    );
  };

  return cardSummaryType === 'cta' ? (
    <StyledCardSummaryContainer
      data-component-name="m-common-cardsummary"
      className={clsx('d-flex', styleclass, customclass)}
      isTertiaryLink={ctaType === 'tertiaryLink'}
    >
      {isMobileView ? (
        <Link
          linkClassName={['d-flex flex-fill']}
          linkHref={ctaLink}
          target={openInNewTab === true ? '_blank' : '_self'}
          trackingProperties={trackingProperties}
          ariaLabel={ctaLabel}
        >
          {getCardContent()}
        </Link>
      ) : (
        getCardContent()
      )}
    </StyledCardSummaryContainer>
  ) : (
    <StyledCardSummaryContainer
      data-component-name="m-common-cardsummary"
      className={clsx(isMobileView ? '' : 'd-flex', styleclass, customclass)}
      isTertiaryLink={ctaType === 'tertiaryLink'}
    >
      {isMobileView ? getRateCardContentMobile() : getRateCardContent()}
    </StyledCardSummaryContainer>
  );
};
