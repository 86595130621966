import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledAccordionContainerEnhanced = styled.span<{
  showExpandCollapseAllControl?: boolean;
  isTextCenterAligned?: boolean;
}>`
  .m-accordion__container {
    &_header {
      margin-bottom: ${props =>
        props?.showExpandCollapseAllControl && props?.isTextCenterAligned ? toRem(0) : toRem(24)};
      p {
        margin: 0;
      }
      padding: ${props =>
        props?.showExpandCollapseAllControl && props?.isTextCenterAligned ? `${toRem(0)} ${toRem(4)}` : ``};
      @media ${baseVariables.mediaQuery.md} {
        padding: ${props =>
          props?.showExpandCollapseAllControl && props?.isTextCenterAligned ? `${toRem(0)} ${toRem(64)}` : ``};
      }
    }
  }
  .expand-collapse-btn {
    flex: 0 0 auto;
  }
`;
