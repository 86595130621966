import { NavigationMockModel } from '../../Navigation';
const mockData =
  NavigationMockModel[':items']['root'][':items']['responsivegrid'][':items']['global_navigation'][':items'][
    'utilityNavigation'
  ][':items']['item_1686289362143'];
export const SignInDrawerMock = {
  logoType: 't-program-logo-alt-color',
  logoUrl: '/',
  logoImageAltText: 'Brand Logo',
  restProps: {
    labelTag: 'marriott-homepage:languagecode/nav---account',
    componentId: '1455',
    openInaNewTab: 'false',
    'cq:panelTitle': 'Sign In or Join',
    resourceProperties: mockData['resourceProperties'],
    listItems: mockData['listItems'],
  },
  renderUtilityLink: () => `Sign In or Join`,
};
export const MockGetUserDetailsRemembered = {
  status: 'FAILURE',
  headerSubtext: {
    image: {
      altText: 'Global Header - My Account',
      src: 'https://cache.marriott.com/marriottassets/icons/mrw_default_profile_placeholder1.png',
    },
    class: 't-header-subtext',
    consumerName: 'Lou',
  },
  rememberedUser: true,
  marketingConsentUrl: null,
  displayMarketingConstentTile: false,
  coreRequiredConsentFlag: null,
  memberNumber: '438******',
  message: 'Unexpected exception/error',
  messageCode: 'SERVER-ERROR-100',
  sessionToken: '',
  componentMessages: null,
  errorRedirectURI: null,
  errorDetails: 'API|500|{0}|GET /v2/consumers/ID/rewards_401',
};

export const MockGetUserDetailsAuthenticated = {
  status: 'success',
  headerSubtext: {
    image: {
      altText: 'Global Header - My Account',
      src: 'https://cache.marriott.com/marriottassets/icons/mrw_default_profile_placeholder1.png',
    },
    class: 't-header-subtext',
    consumerName: 'Lou',
  },
  rememberedUser: false,
  marketingConsentUrl: null,
  displayMarketingConstentTile: false,
  coreRequiredConsentFlag: null,
  trips: {
    url: '/rewards/findReservationList.mi',
    upcomingReservationsCount: 3,
  },
  userProfileSummary: {
    level: 'Titanium Elite',
    membershipCode: 'PPE',
    currentPoints: '9,483,500',
    numberOfNights: '6',
    membershipLifetimeStatus: null,
    eliteLifetimelevelDescription: null,
    hubLifeTimeClass: null,
    lifeTimeLevel: true,
  },
  memberNumber: '',
  buyPointsLink: true,
};
