import React from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Media as MediaComponentComp } from '@marriott/mi-ui-library';

export const MediaComponentConfig = {
  emptyLabel: 'Media',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/media`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Media = (props: any) => {
  const model = props.model || {};
  const { styleclass, componentId } = model;
  if (model.styleclass) {
    model.styleclass = '';
  }
  return (
    <div
      data-testid="media-component"
      data-component-name="o-common-static-media"
      id={componentId}
      className={clsx(styleclass)}
    >
      <EditableComponent config={MediaComponentConfig} {...props}>
        <MediaComponentComp {...props} />
      </EditableComponent>
    </div>
  );
};
