// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem } from '../../styles';
import { baseVariables } from '../../styles';

export const StyledMiniCardCarouselContainer = styled.div`
  background-color: red;
`;

export const StyledCardCarousel = styled.div`
  .minicardcontiner {
    background: white;
    margin: 1rem 0px;
  }
  .containerli {
    &:first-child {
      margin-left: 0 !important;
    }
  }
  .carousal_header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .heading_container {
      position: relative;
      top: 12px;
      h3 {
      }
    }
    .carousal_header--cta {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: toRem(8);
      button {
        background: none;
      }
    }
  }
  .glide {
    @media ${baseVariables.mediaQuery.md} {
      ul {
        width: 100% !important;
        li {
          width: inherit;
        }
      }
    }

    &__track {
      padding-top: ${toRem(24)};
      .glide__slides_authoring {
        width: 110% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    &__track__pt3 {
      overflow: hidden;
      padding-top: ${toRem(8)};
      .glide__slides_authoring {
        width: 110% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
      .carouselControlType1 {
        .right-arrow {
          right: -1rem;
          @media ${baseVariables.mediaQuery.sm} {
            right: -1.25rem;
          }
        }
        .left-arrow {
          left: -1rem;
          @media ${baseVariables.mediaQuery.sm} {
            left: -1.25rem;
          }
        }
      }
    }
  }
  .glide--swipeable {
    cursor: default;
  }
  .full-width-cards-controls {
    display: none;
  }
`;
