import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledAccordionEnhanced = styled.div<{ enableFullWidthHeader: boolean | undefined }>`
  .accordion {
    height: 100%;
    &__container {
      display: flex;
      flex-direction: column;
      height: 100%;
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(32)};
        padding-left: ${toRem(16)};
      }
      .accordion__list {
        overflow: hidden;
        margin: ${toRem(8)} 0 ${toRem(24)} 0;
        transition: all 0.4s ease;
        @media ${baseVariables.mediaQuery.md} {
          transition: none;
        }
        &.collapsed {
          max-height: 0;
          padding: 0;
        }
        &.hide-visibility {
          visibility: hidden;
          margin: 0;
        }
      }
      .seperator {
        display: block;
        height: ${toRem(1)};
        max-width: 100%;
        margin-bottom: ${toRem(8)};
        width: 100%;
        color: ${baseVariables.color.neutral20};
      }
      .accordion__heading {
        display: flex;
        justify-content: space-between;
        align-items: end;
        background: none;
        width: 100%;
        padding: 0;
        color: inherit;
        margin: ${toRem(25)} 0;
        &:focus-visible {
          outline: -webkit-focus-ring-color auto ${toRem(1)};
        }
        .icon-arrow-down,
        .icon-arrow-up {
          margin-left: ${toRem(16)};
          height: ${toRem(24)};
        }
        .accordion__icon {
          height: ${toRem(24)};
        }
        .accordion__title {
          margin-right: auto;
          ${props => (props?.enableFullWidthHeader ? 'width: 100%;' : '')}
          p {
            margin: 0;
          }
        }
        .accordion__image {
          margin: ${toRem(12)} 0;
          .accordion_image_size {
            max-height: ${toRem(50)};
            max-width: ${toRem(100)};
          }
        }
      }
    }
  }
`;
