// Styles for __name__ go here.
import styled, { css } from 'styled-components';
import { baseVariables, contextualVariable, toRem } from '../../styles';

export const StyledMediaComponent = styled.picture`
  img {
    width: -webkit-fill-available;
  }
  height: -webkit-fill-available;
  display: block;
  position: relative;
`;
export const IconBlock = styled.a<{ position: string }>`
  position: absolute;
  right: 0;
  display: flex;
  gap: ${toRem(8)};
  align-items: center;
  background: ${contextualVariable['modalBackshadowBgColor']};
  padding: ${toRem(4)};
  border-radius: ${toRem(4)};
  margin: ${toRem(8)};
  @media ${baseVariables.mediaQuery.md} {
    margin: ${toRem(16)};
  }
  .icon-inverse::before {
    max-height: ${toRem(14)};
    max-width: ${toRem(14)};
  }
  .label-custom {
    line-height: ${toRem(12)};
  }
  ${({ position }) =>
    position === 'bottomRight' &&
    css`
      bottom: 0;
    `}
  ${({ position }) =>
    position === 'topRight' &&
    css`
      top: 0;
    `}
`;
