import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledLinkBannerComp = styled.div`
  &.lb {
    padding: 0;
    @media ${baseVariables.mediaQuery.md} {
      padding: 0 ${toRem(8)};
    }
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    @media ${baseVariables.mediaQuery.md} {
      border-bottom: none;
    }
  }
  .lb__container {
    padding: 0;
    margin: ${toRem(8)} 0;
    height: 100%;
    @media ${baseVariables.mediaQuery.md} {
      padding: 0 ${toRem(8)};
      margin: 0;
      border-bottom: none;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding-left: ${toRem(62)};
      padding-right: ${toRem(32)};
    }
  }
  .lb_container_four_columns {
    @media ${baseVariables.mediaQuery.lg} {
      padding-left: ${toRem(8)};
      padding-right: ${toRem(32)};
    }
  }
  .lb_container_single_column {
    padding-right: ${toRem(16)};
  }
  .lb__button {
    color: inherit;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    padding: 0px;
  }
  .lb__linkCollection {
    li:last-child {
      padding-bottom: ${toRem(8)};
    }
  }
`;

export const StyledLinkBanner = styled.div<{ noOfElements: number }>`
  overflow-y: hidden;
  .lb-heading {
    font-weight: 700;
    margin-top: ${toRem(50)};
    @media ${baseVariables.mediaQuery.md} {
      line-height: ${toRem(30)};
      font-size: ${toRem(27)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      line-height: ${toRem(32)};
      font-size: ${toRem(29)};
    }
  }
  .lb_without_dividers {
    border-right: 0 !important;
    border-bottom: 0 !important;
  }
  .lb__wrapper {
    display: flex;
    padding: ${toRem(32)} ${toRem(8)};
    flex-direction: column;
    @media ${baseVariables.mediaQuery.md} {
      flex-direction: row;
    }
    .lb:nth-child(${props => props.noOfElements}) {
      @media ${baseVariables.mediaQuery.md} {
        border-right: none;
      }
    }
  }
  .lb {
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    border-right: none;
    @media ${baseVariables.mediaQuery.md} {
      border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
      border-bottom: none;
    }
    &:first-child {
      .lb__container {
        padding-left: 0;
      }
      .lb_container_single_column {
        padding-left: ${toRem(16)};
      }
    }
  }
`;
