import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

// export const StyledDataBlock = styled.div<{ imageSize: string }>`
export const StyledDataBlock = styled.div`
  &.carblock-center-align {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &.coral {
    .seperator {
      background-color: ${baseVariables.color.accent10};
    }
  }
  .seperator-horizontal {
    border: none;
    height: ${toRem(1)};
    width: ${toRem(80)};
    margin-bottom: ${toRem(12)};
  }
  .seperator-vertical {
    height: ${toRem(80)};
    width: ${toRem(1)};
    margin-right: ${toRem(16)};
  }
  .data-block-large-icon:before {
    font-size: ${toRem(40)};
    height: ${toRem(40)};
  }

  .data-block-img {
    .logo {
      max-width: ${toRem(90)};
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(135)};
      }
    }
    .small {
      width: ${toRem(24)};
    }
    .large {
      width: ${toRem(40)};
    }
  }
  [class*='brand-logo-']:hover {
    background-color: transparent;
  }
  .data-block-logo-icon {
    line-height: 1.25;
  }
  .brand {
    &-logo-RZ::before {
      font-size: ${toRem(36.32)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(54.51)};
      }
    }
    &-logo-EB::before {
      font-size: ${toRem(16.54)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(24.81)};
      }
    }
    &-logo-JW::before {
      font-size: ${toRem(35.45)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(53.2)};
      }
    }
    &-logo-AK::before {
      font-size: ${toRem(37.53)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(56.3)};
      }
    }
    &-logo-BR::before {
      font-size: ${toRem(47.18)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(70.77)};
      }
    }
    &-logo-GE::before {
      font-size: ${toRem(43.68)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(65.52)};
      }
    }
    &-logo-CY::before {
      font-size: ${toRem(13.92)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(20.88)};
      }
    }
    &-logo-RI::before {
      font-size: ${toRem(16.18)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(24.27)};
      }
    }
    &-logo-SH::before {
      font-size: ${toRem(33.79)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(50.68)};
      }
    }
    &-logo-FI::before {
      font-size: ${toRem(25.34)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(38.01)};
      }
    }
    &-logo-TS::before {
      font-size: ${toRem(30.14)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(45.21)};
      }
    }
    &-logo-PR::before {
      font-size: ${toRem(39.26)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(58.89)};
      }
    }
    &-logo-OX::before {
      font-size: ${toRem(30.52)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(45.79)};
      }
    }
    &-logo-LC::before {
      font-size: ${toRem(49.15)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(73.72)};
      }
    }
    &-logo-WI::before {
      font-size: ${toRem(20.06)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(30.09)};
      }
    }
    &-logo-SI::before {
      font-size: ${toRem(42.24)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(63.36)};
      }
    }
    &-logo-MD::before {
      font-size: ${toRem(13.24)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(19.87)};
      }
    }
    &-logo-TX::before {
      font-size: ${toRem(18.62)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(27.93)};
      }
    }
    &-logo-DS::before {
      font-size: ${toRem(9.3)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(13.97)};
      }
    }
    &-logo-AL::before {
      font-size: ${toRem(42.04)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(63.07)};
      }
    }
    &-logo-HV::before {
      font-size: ${toRem(38.97)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(58.464)};
      }
    }
    &-logo-WV::before {
      font-size: ${toRem(30.46)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(45.7)};
      }
    }
    &-logo-MG::before {
      font-size: ${toRem(27.61)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(41.42)};
      }
    }
    &-logo-WH::before {
      font-size: ${toRem(12.4)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(18.6)};
      }
    }
    &-logo-RCR::before {
      font-size: ${toRem(14.42)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(21.6)};
      }
    }
    &-logo-BA::before {
      font-size: ${toRem(47.12)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(70.68)};
      }
    }
    &-logo-BG::before {
      font-size: ${toRem(20.8)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(31.2)};
      }
    }
    &-logo-XE::before {
      font-size: ${toRem(28.8)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(41.6)};
      }
    }
    &-logo-MC::before {
      font-size: ${toRem(66.56)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(99.84)};
      }
    }
    &-logo-FP::before {
      font-size: ${toRem(64)};
    }
    &-logo-DE::before {
      font-size: ${toRem(64)};
    }
    &-logo-AR::before {
      font-size: ${toRem(64)};
    }
    &-logo-XR::before {
      font-size: ${toRem(64)};
    }
    &-logo-EL::before {
      font-size: ${toRem(64)};
    }
    &-logo-MV::before {
      font-size: ${toRem(64)};
    }
    &-logo-ER::before {
      font-size: ${toRem(43.36)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(65.04)};
      }
    }
    &-logo-SV::before {
      font-size: ${toRem(47.63)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(71.45)};
      }
    }
    &-logo-Escape {
      &::before {
        font-size: unset;
        line-height: unset;
      }
      font-size: ${toRem(50.6)};
      margin-left: -1.1rem;
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(76)};
        margin-left: -1.5rem;
      }
    }
      &-logo-XF::before {
      font-size: ${toRem(30.4)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(44.8)};
      }
    }
      &-logo-RS::before {
      font-size: ${toRem(20.8)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(30.5)};
      }
    }
      &-logo-SN::before {
      font-size: ${toRem(60.8)};
      @media ${baseVariables.mediaQuery.lg} {
        font-size: ${toRem(89.6)};
      }
  }
  .data-block-number-desc {
    width: 50%;
    height: ${toRem(40)};
    overflow: hidden;
  }
  .data-block-number-content {
    flex: 1;
  }
  .data-block-text-content {
    margin: ${toRem(12)} ${toRem(0)};
    p {
      margin: ${toRem(0)};
    }
  }
  .data-block-heading {
    margin-bottom: ${toRem(2)};
  }
`;
