import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardSummary as CardSummaryComp } from '@marriott/mi-ui-library';
import { CardSummaryProps } from './CardSummary.types';

export const CardSummaryConfig = {
  emptyLabel: 'CardSummary',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cardsummary`,
};

export const CardSummary = (props: CardSummaryProps) => {
  const { componentId } = props;
  return (
    <div id={componentId} data-component-name="o-common-static-cardsummary" data-testid="card-summary">
      <EditableComponent config={CardSummaryConfig} {...props}>
        <CardSummaryComp {...props} />
      </EditableComponent>
    </div>
  );
};
