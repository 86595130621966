import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CarouselGallery as CarouselGalleryComp } from '@marriott/mi-ui-library';
import { CarouselGalleryProps } from './CarouselGallery.types';

export const CarouselGalleryConfig = {
  emptyLabel: 'CarouselGallery',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/carouselgallery`,
};

export const CarouselGallery = (props: CarouselGalleryProps) => {
  return (
    <div data-testid="carousel-gallery" data-component-name="o-common-static-carouselgallery">
      <EditableComponent config={CarouselGalleryConfig} {...props}>
        <CarouselGalleryComp {...props} />
      </EditableComponent>
    </div>
  );
};
