import React, { FC, useRef, useEffect, useState } from 'react';
import Glide from '@glidejs/glide';
import { BannerFeatureItem, BannerFeatureProps } from './BannerFeature.types';
import {
  StyledBannerFeature,
  BannerTextSection,
  BannerBlock,
  ImageWrapper,
  BannerLeftSection,
} from './BannerFeature.styles';
import { Button, Eyebrow, Heading, Image, RichText, headingType } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { size } from '../../utils/enums/enums';

export const BannerFeature: FC<BannerFeatureProps> = props => {
  const { trackingProperties, componentId, bannerFeatureItems, contentAlignment, styleclass } = props;

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};
  const glideRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageWrapperWidth, setImageWrapperWidth] = useState<string>('58.33%');

  useEffect(() => {
    const updateWidths = () => {
      if (containerRef.current) {
        const screenWidth = window.innerWidth;
        const containerWidth = containerRef.current.offsetWidth;
        const difference = (screenWidth - containerWidth) / 2;

        if (screenWidth < 992) {
          setImageWrapperWidth('100%');
        } else {
          const baseImageWrapperWidth = (containerWidth * 58.33) / 100;
          const newImageWrapperWidth = baseImageWrapperWidth + difference;
          setImageWrapperWidth(`${newImageWrapperWidth}px`);
        }
      }
    };
    updateWidths();
    window.addEventListener('resize', updateWidths);

    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, []);

  useEffect(() => {
    if (!glideRef.current || bannerFeatureItems?.length <= 1) return;
    const carouselRootSelector = `#${componentId}`;
    let glide: Glide.Properties | Glide.Static;
    if (document?.querySelector(carouselRootSelector)) {
      try {
        glide = new Glide(carouselRootSelector, {
          type: 'carousel',
          startAt: 0,
          perView: 1,
          focusAt: 'center',
          gap: 0,
          autoplay: false,
          animationDuration: 600,
          rewind: false,
        });

        glide.on('run.before', move => {
          const currentIndex = (glide as Glide.Properties).index;
          console.log(`Current index: ${currentIndex}, move direction: ${move.direction}`);
          if (move.direction === '>' && currentIndex === bannerFeatureItems.length - 1) {
            move.direction = '=';
            move.steps = currentIndex;
          } else if (move.direction === '<' && currentIndex === 0) {
            move.direction = '=';
            move.steps = currentIndex;
          }
        });

        glide?.mount();
      } catch (error) {
        // console.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    return () => {
      if (glide) {
        (glide as Glide.Properties)?.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, bannerFeatureItems?.length]);

  const Cta = ({ item }: { item: BannerFeatureItem }) => {
    if (!item.ctaLabel) {
      return null;
    }

    const isLink = item.ctaType === 'tertiaryLink';
    const buttonCallback = () => {
      window.open(item.ctaLink, item.openInNewTab ? '_blank' : '_self');
    };

    return (
      <Button
        href={item.ctaLink}
        isLink={isLink}
        target={item.openInNewTab ? '_blank' : '_self'}
        callback={!isLink ? buttonCallback : undefined}
        className={[
          item.ctaType === 'primaryButton' ? 'm-button-primary' : '',
          item.ctaType === 'secondaryButton' ? 'm-button-secondary' : '',
          isLink ? 'm-link-tertiary-button' : '',
          item.openInNewTab
            ? item.ctaType === 'primaryButton' || item.ctaType === 'secondaryButton'
              ? 'm-button-external'
              : 'm-link-tertiary-button-external'
            : '',
        ].join(' ')}
        custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
          item.openInNewTab ? 'external' : 'internal'
        }`}
        buttonCopy={item.ctaLabel}
        isTextBeforeChildren={false}
      />
    );
  };

  return (
    <StyledBannerFeature
      data-component-name="m-ui-library-BannerFeature"
      data-testid="ui-library-BannerFeature"
      {...customAttributes}
      id={componentId}
      className={clsx(styleclass, 'm-container-fullbleed')}
    >
      <div ref={glideRef} className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {Array.isArray(bannerFeatureItems) &&
              bannerFeatureItems.map((item, index) => (
                <BannerBlock className="glide__slide m-0" key={index}>
                  <BannerLeftSection>
                    <div ref={containerRef} className="container p-0">
                      <BannerTextSection className={clsx(contentAlignment === 'center' && 'text-center', 'col-lg-4')}>
                        {item.eyebrow && <Eyebrow text={item.eyebrow} customClass={clsx('d-block mb-3')} />}
                        <Heading
                          customClass={clsx('mb-3')}
                          variation={headingType.title}
                          element={item.headerTag}
                          dangerouslySet={item.header}
                          fontSize={size.small}
                        />
                        <RichText
                          text={item.descriptionText}
                          componentId={`${componentId}-descriptiontext`}
                          customClass={clsx('t-body-m mb-4 t-font-alt-m')}
                        />
                        <Cta item={item} />
                      </BannerTextSection>
                    </div>
                  </BannerLeftSection>
                  <ImageWrapper className="p-0" style={{ width: imageWrapperWidth }}>
                    {item.fileReferenceImagePath && (
                      <Image
                        defaultImageURL={item.fileReferenceImagePath.assetPath}
                        renditions={item.fileReferenceImagePath.renditions}
                        altText={item.fileReferenceImagePath.altText}
                        dynamic={item.fileReferenceImagePath.dynamic}
                      />
                    )}
                  </ImageWrapper>
                </BannerBlock>
              ))}
          </ul>
        </div>
        {bannerFeatureItems?.length > 1 && (
          <div className="center-align-controls">
            <div className={clsx('carouselControlType2')}>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('left-arrow', 'glide__arrow--left')}
                  id={`${componentId}_left_arrow`}
                  data-glide-dir="<"
                  data-content="Previous"
                  aria-label="Previous slide"
                  aria-controls={`${componentId}_carousel`}
                >
                  <span className="icon-arrow-left" />
                </button>
              </div>
              <input
                type="hidden"
                id="left-arrow-click-track"
                className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.leftArrowDesc}|internal`}
              />
              <div data-glide-el="controls[nav]">
                {bannerFeatureItems?.map((_, index) => (
                  <button key={index} data-glide-dir={`=${index}`} aria-label={`Go to slide ${index + 1}`}></button>
                ))}
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={clsx('right-arrow', 'glide__arrow--right')}
                  id={`${componentId}_right_arrow`}
                  data-glide-dir=">"
                  data-content="Next"
                  aria-label="Next slide"
                  aria-controls={`${componentId}_carousel`}
                >
                  <span className="icon-arrow-right" />
                </button>
              </div>
              <input
                type="hidden"
                id="right-arrow-click-track"
                className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.rightArrowDesc}|internal`}
              />
            </div>
          </div>
        )}
      </div>
    </StyledBannerFeature>
  );
};
